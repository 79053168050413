<template>
  <div>
    <div
      class="fixed top-0 left-0 right-0 flex items-center justify-between p-6 z-20 nav-bg"
    >
      <div class="w-1/3">
        <router-link to="/" class="font-bold">Home</router-link>
      </div>
      <div class="w-1/3 flex space-x-6 text-sm justify-end">
        <button @click="$store.commit('TOGGLE_ZOOM')">
          <span v-if="zoomedOut">Zoom In</span>
          <span v-else>Zoom Out</span>
        </button>
        <input
          ref="input"
          v-model="searchTerm"
          class="bg-white rounded-full px-6 py-2 w-72 shadow-sm focus:shadow-lg hover:shadow-lg transition-all duration-300 text-sm relative z-20"
          placeholder="Search labels"
          autofocus
        />
      </div>
    </div>

    <div class="mx-6 mt-24">
      <div v-if="searching" class="pulse text-center">
        Searching for {{ searchTerm }}...
      </div>
      <div
        v-if="results.length"
        class="mt-16"
        :class="{
          'flex flex-wrap gap-12 justify-center': zoomedOut,
          'space-y-16': !zoomedOut,
        }"
      >
        <Label
          v-for="label in results"
          :key="label._id"
          :label="label"
          :zoomedOut="zoomedOut"
          :showGroup="true"
          :class="{
            'transform zoomed-out': zoomedOut,
            'mx-auto': !zoomedOut,
          }"
        />
      </div>
      <div
        @click="searchTerm = ''"
        v-else-if="noResults && searchTerm"
        class="rounded-lg p-6 bg-white transition-all duration-300 shadow-sm hover:shadow-lg cursor-pointer flex justify-between mx-auto"
      >
        <div>
          Nothing found for
          <span class="italic">{{ searchTerm }}</span
          >.
        </div>
        <div>Click to reset the search</div>
      </div>
      <div v-else-if="!searchTerm" class="text-center">
        <div>Please enter a search term</div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import api from "../api.js";
import Label from "@/components/Label";

export default {
  name: "Search",
  data() {
    return {
      modal: false,
      searching: false,
      searchTerm: "",
      results: [],
      noResults: false,
    };
  },
  computed: {
    zoomedOut() {
      return this.$store.state.zoomedOut;
    },
  },
  methods: {
    search: debounce(function (term) {
      if (term == "") {
        this.results = [];
      } else {
        this.searching = true;
        this.results = [];
        api(this, `/search/${term}`).then((data) => {
          window.setTimeout(() => {
            this.results = data;
            this.searching = false;

            if (this.results.length === 0) {
              this.noResults = true;
            }
          }, 1000);
        });
      }
    }, 500),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.params.term) {
        this.searchTerm = this.$route.params.term;

        this.search(this.searchTerm);
      }
    });
  },
  components: {
    Label,
  },
  watch: {
    searchTerm() {
      this.noResults = false;
      this.search(this.searchTerm);

      this.$nextTick(() => {
        this.$router.replace({
          params: {
            term: this.searchTerm,
          },
        });
      });
    },
  },
};
</script>
